import tokens, { type TokenIconUniquenessType } from 'public/img/tokens';
import { tokenMetaData } from '~/utils/index';
import { type Tokens, TokenUniquenessType } from '~/utils/constants';
import type { RecipeProduct, RecipeProps } from '~/types/crafting';
import type { TSeasonConfigItem } from '~/types/season';

const { blockchain } = useEnvs();

export function getIconToken(product: RecipeProduct | TSeasonConfigItem, key?: number): string {
  const productTokenAddress = product.tokenAddress;
  const icon: string | string[] | TokenIconUniquenessType =
    tokens[tokenMetaData(blockchain.contracts, productTokenAddress).name.contract as Tokens];

  const isCollectionIcons = Array.isArray(icon);
  const isSingleIcon = typeof icon === 'string';
  const isUniquenessIcons = !isSingleIcon && ('common' in icon || 'uncommon' in icon || 'rare' in icon);

  if ('pointsMultiplier' in product && !isCollectionIcons && isUniquenessIcons) {
    return icon[TokenUniquenessType.Common];
  }

  if ('productId' in product) {
    const productUniqueness = getProductUniqueness(product);

    if (productUniqueness && !isCollectionIcons && isUniquenessIcons) {
      return icon[productUniqueness as TokenUniquenessType];
    }
  }

  if (typeof key === 'number' && isCollectionIcons) {
    return icon[key];
  }

  return icon as string;
}

export function getProductUniqueness(product: RecipeProduct | undefined): string {
  if (!product) {
    return '';
  }

  return getNftValueByAddress(blockchain.contracts, product);
}

export function getAltAttribute(product: RecipeProduct): string {
  const productTokenAddress = product.tokenAddress;
  return tokenMetaData(blockchain.contracts, productTokenAddress).label;
}

export function isShip(product: RecipeProduct): boolean {
  return product.tokenAddress === blockchain.contracts.ships.addresses.contract;
}

export function getShipIndex(listIndex: number, recipes: RecipeProps[] | null): number {
  if (recipes && recipes.length) {
    const recipesWithoutShips = recipes.filter((recipe) => !isShip(recipe.products[0]));

    return listIndex - recipesWithoutShips.length;
  }

  return 0;
}
