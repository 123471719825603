<template>
  <div class="pearl-packs-container">
    <div class="top-content">
      <br v-if="!$device.isMobile" />
      <p class="crafting-description">{{ props.description }}</p>
      <p class="apr">
        {{ $t('appStatisticAPR') }}:
        {{ aprPercent }}
        %
      </p>
      <div v-if="Number(minedBalance) > 0" class="ready-for-claim">
        <div class="ready-for-claim-amount">
          <figure class="crafting-item-figure">
            <img
              :src="tokensConfig[props.token || Tokens.pearl].media"
              :alt="tokensConfig[props.token || Tokens.pearl].label"
            />
          </figure>
          <p>{{ BigNumber(minedBalance).decimalPlaces(2) }}</p>
        </div>
        <div v-if="tokensConfig[props.token].extraReward && minedExtraRewardBalance" class="ready-for-claim-amount">
          <figure class="crafting-item-figure">
            <img
              :src="tokensConfig[props.token || Tokens.pearl].extraReward?.media"
              :alt="tokensConfig[props.token || Tokens.pearl].extraReward?.label"
            />
          </figure>
          <p>{{ format(minedExtraRewardBalance) }}</p>
        </div>
        <button
          class="btn-primary"
          type="button"
          :loading="loadingClaim"
          :disabled="loadingClaim"
          @click="claimRewardHandler"
        >
          <span>{{ $t('appStatisticClaim') }}</span>
          &nbsp;<span v-if="claimConfNumber && loadingClaim"
            >{{ 0 }} / {{ blockchain.minConfirmationsCount }} &nbsp;</span
          >

          <IconsChevronRight />
        </button>
      </div>
    </div>

    <div class="w-layout-grid buyPackContainer">
      <BuyPackButton
        v-for="pack in filteredPacks"
        :key="pack.name"
        :pack-key="pack.key"
        :price="pack.price"
        :token-reward="pack.tokenReward"
        :extra-rewards="pack.extraRewards"
        :extra-reward-key="pack.extraRewardKey || ''"
        :name="pack.name"
        :image-path="pack.imagePath"
        :token="pack.token"
        :pack-description-key="pack?.descriptionKey"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BigNumber } from 'bignumber.js';
import { useI18n } from '#imports';
import type { IPercentAPRResponse } from '~/types/apiService';
import {
  apiUrls,
  COAL_PACK_DATA,
  GOLD_ORE_PACK_DATA,
  Tokens,
  tokensConfig,
  type PackItem,
  WOOD_PACK_DATA,
  IRON_ORE_PACK_DATA
} from '~/utils/constants';
import { useWalletInfo, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { formatEther } from 'ethers';

const { t } = useI18n();
const { apiUrl, blockchain } = useEnvs();
const store = useMainStore();
const { address } = useWeb3ModalAccount();
const { walletProvider } = useWeb3ModalProvider();
const { walletInfo } = useWalletInfo();
const { claimReward } = useStakingMethods();
const { claimMinedTokens } = useMineLicense();

const minedBalance = ref('0');
const minedExtraRewardBalance = ref('0');
const claimConfNumber = ref(0);
const loadingClaim = ref(false);
const { $errorHandler } = useNuxtApp();
const { getContractReadOnly } = useAbiAccess();

const props = defineProps<{
  token: Tokens;
  description: string;
  isBuilding?: Boolean;
}>();

watch(
  [address, () => props.token, () => store.notificationUpdateVersion],
  async ([newAddress]) => {
    if (!newAddress || !props.token) {
      minedBalance.value = '0';
      return;
    }

    const mineContract = await getContractReadOnly('mine', blockchain.contracts[props.token].addresses.mine || '');
    const minedContractBalance = await mineContract.mined(newAddress);

    minedBalance.value = Number(formatEther(minedContractBalance[0])).toFixed(2);
    minedExtraRewardBalance.value = Number(formatEther(minedContractBalance[1])).toFixed(2);
  },
  { immediate: true }
);

const { data: aprData } = useFetch<IPercentAPRResponse>(apiUrls.token.apr, {
  baseURL: apiUrl,
  query: {
    tokenAddress: blockchain.contracts[props.token].addresses.contract
  },
  pick: ['apr'],
  immediate: true
});

const format = (value: string | number, decimals = 2) => {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
};

const getPackData = (): PackItem[] => {
  switch (props.token) {
    case Tokens.coal:
      return COAL_PACK_DATA;
    case Tokens.goldOre:
      return GOLD_ORE_PACK_DATA;
    case Tokens.gold:
      return [];
    case Tokens.wood:
      return WOOD_PACK_DATA;
    case Tokens.ironOre:
      return IRON_ORE_PACK_DATA;
    default:
      return [];
  }
};

const filteredPacks = computed(() => {
  const tokenPacks = getPackData();

  if (props.isBuilding) return tokenPacks;
  else return [];
});

const aprPercent = computed(() =>
  aprData.value?.apr ? format(new BigNumber(aprData.value?.apr).toString(), 2) : '...'
);

const claimRewardHandler = async () => {
  if (!address.value || !walletProvider.value) {
    alert('Please unlock wallet!');
    return;
  }
  claimConfNumber.value = 0;

  try {
    loadingClaim.value = true;
    const receipt =
      props.token === Tokens.pearl
        ? await claimReward(() => {
            claimConfNumber.value += 1;
          }, props.token)
        : await claimMinedTokens(() => {
            claimConfNumber.value += 1;
          }, props.token);

    if (receipt?.hash) {
      await PearlApiService.pushActivity(apiUrl, {
        address: address.value ?? '',
        transactionHash: receipt.hash,
        value: 0,
        type: 'REWARD_CLAIM'
      });
      saveHashToLocalStorage(t('notificationClaimReward'), receipt.hash);
      store.updateVersion();

      await receipt?.wait(blockchain.minConfirmationsCount);
      const confirmations = await receipt?.confirmations();

      if (confirmations >= blockchain.minConfirmationsCount) {
        loadingClaim.value = false;
        modifyHistoryHashStatus(receipt.hash, 'Done');
        store.updateVersion();
      }
    }
  } catch (e: unknown) {
    loadingClaim.value = false;
    if (walletInfo.value?.name) {
      const errorMessage = getCancelErrorMessage(e, walletInfo.value.name);
      $errorHandler(t('notificationFailedToUnlock') + t(errorMessage));
    }
  }
};
</script>

<style lang="scss">
.buyPackContainer {
  .lot-image-scaled {
    transform: scale(1.1);
    margin-top: 12px;
  }
}
</style>

<style lang="scss" scoped>
.top-content {
  margin-bottom: 30px;

  .crafting-description {
    text-align: center;
    margin-top: -40px;
    padding: 10px 0px;
  }
}

.ready-for-claim {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 12px;
  max-width: fit-content;
  border: 2px solid #1e353e;
  background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);
  margin: 0 auto;

  @media screen and (max-width: 479px) {
    width: 100%;
  }

  &-amount {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 24px;
    color: #fff;

    p {
      padding-top: 4px;
    }
  }

  &-amount:not(:first-child) {
    margin-left: 10px;
  }

  button {
    margin-left: 20px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #04202b;
    flex-shrink: 0;

    &:hover {
      color: #fff;
    }
    svg {
      margin-left: 8px;
      padding-top: 1px;
      path {
        fill: currentColor;
        transition: all 0.3s;
      }
    }

    @media screen and (max-width: 479px) {
      width: auto;
    }

    p {
      margin-bottom: -5px;
      margin-left: 5px;
      font-weight: 800;
      font-size: 24px;
      color: #fff;
      font-family: Eczar;
    }
  }
}

.description {
  color: #90989d;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  max-width: 670px;
  margin: auto;

  &-more {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }
}

.apr {
  font-size: 24px;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  display: grid;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 18px;
  max-height: 55vh;
  overflow: auto;

  @media screen and (max-width: 767px) {
    height: auto;
    max-height: 100%;
  }
}

.buyPackContainer {
  .lot-image-scaled {
    transform: scale(1.1);
    margin-top: 13px;
  }
}

.offers-list {
  margin-bottom: 48px;
}

.card {
  overflow: hidden;
}

.offers-title {
  text-align: center;
  font-size: 24px;
  font-family: Grenze, sans-serif;
  margin-bottom: 20px;
  color: var(--main-text-color);
  font-weight: 700;
}

.offers-wrapper {
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid var(--blue-2);
  background: var(--blue-3);
  margin-bottom: 20px;
}

.sale-wrapper,
.shop-wrapper {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.offer {
  display: flex;
  align-items: baseline;
  transition: ease all 200ms;
  cursor: pointer;
}

.card.pd-48px---32px.shop-item {
  color: rgba(255, 255, 255, 0.5);
  background-color: #021b26;
  background-image: radial-gradient(circle closest-corner at 50% 0, #3b7285, rgba(0, 0, 0, 0));
  text-decoration: none;
  transition:
    all 0.3s,
    color 0.3s;

  &:hover {
    background-image: radial-gradient(circle closest-corner at 50% 0, #3faa58, rgba(0, 0, 0, 0));
  }
}

@media screen and (max-width: 991px) {
  .card.pd-48px---32px.shop-item {
    background-image: radial-gradient(circle farthest-side at 50% 0, #3b7285, rgba(0, 0, 0, 0) 50%);
    flex-direction: column;
    align-items: stretch;
    padding: 16px 20px;
    display: flex;
  }

  .w-layout-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .card.pd-48px---32px.shop-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 479px) {
  .card.pd-48px---32px.shop-item {
    padding: 12px 8px;
    font-size: 12px;
  }

  .w-layout-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-wrapper {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  align-items: flex-start;
}

@media screen and (max-width: 991px) {
  .grid-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-wrapper {
    flex-direction: column;
    display: flex;
  }
}
</style>

<style scoped lang="scss">
.el-dialog {
  max-width: 100% !important;
  &__body {
    max-width: 100% !important;
  }
  &__title {
    font-size: 32px !important;
  }
}
</style>
